import React, { useState, useEffect } from "react";
import Carousel from "./Carousel";
import img1 from "../assets/img11.png";
import img2 from "../assets/img12.png";
import img3 from "../assets/img13.png";

const slides = [
  {
    picture: img1,
    review:
      "BizConnect Solutions has been a valuable partner in our lead generation efforts.",
    reviewSmall: "BizConnect is a valuable partner.",
    name: "James Brown, Business Broker",
  },
  {
    picture: img2,
    review:
      "BizConnect Solutions has exceeded our expectations. Their professional and reliable lead generation has been instrumental in helping us close more deals and grow our business.",
    reviewSmall: "BizConnect exceeded our expectations.",
    name: "Robert Lee, Business Broker",
  },
  {
    picture: img3,
    review:
      "The team at BizConnect Solutions consistently delivers top-notch leads and scheduled meetings.",
    reviewSmall: "The team at BizConnect is great at their job.",
    name: "Sophia Martinez, M&A Advisor",
  },
];

const CarouselParent = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  // Check screen size on initial render and on resize
  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };

    checkScreenSize(); // Initial check

    window.addEventListener("resize", checkScreenSize);
    return () => window.removeEventListener("resize", checkScreenSize);
  }, []);

  return (
    <div className="p-10 mt-32">
      <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center tracking-wide mb-5">
        What clients
        <span className="text-[#0434ff]"> say about us.</span>
      </h2>
      <div className="flex justify-center items-center md:h-[500px] bg-[#040404] rounded-2xl">
        <div className="">
          <Carousel autoSlide={true}>
            {slides.map((slide, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-white font-bold p-4"
              >
                <img
                  src={slide.picture}
                  alt=""
                  className="md:w-36 md:h-36 h-20 w-20 rounded-full mb-4"
                />
                <p className="lg:text-xl text-xs italic mb-4 text-[#0434ff] text-center break-words max-w-xs">
                  "{isSmallScreen ? slide.reviewSmall : slide.review}"
                </p>
                <p className="text-xs">{slide.name}</p>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default CarouselParent;
