import React from "react";
import { testimonials } from "../constants";

import { ArrowUpFromDot } from "lucide-react";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const Testimonials = () => {
  const [isInView, setIsInView] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsInView(entry.isIntersecting);
      },
      {
        threshold: 0.1, // Trigger when 10% of the component is in view
      }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const variants = {
    hidden: { opacity: 0, x: -100 },
    visible: (i) => ({
      opacity: 1,
      x: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.6,
        ease: "easeOut",
      },
    }),
  };

  return (
    <div ref={sectionRef} className="mt-32">
      <div className="text-center justify-center items-center">
        <h2 className="text-3xl sm:text-5xl lg:text-6xl text-center tracking-wide mb-5">
          Our
          <span className="text-[#0434ff]"> Goals</span>
        </h2>
        <div className="flex flex-wrap justify-center">
          {testimonials.map((item, index) => (
            <motion.div
              className="flex flex-col text-white bg-gradient-to-r from-[#0434ff] to-[#040404] m-2 lg:w-1/4 sm:w-1/2 p-5 text-xl text-left"
              key={index}
              custom={index}
              initial="hidden"
              animate={isInView ? "visible" : "hidden"}
              variants={variants}
            >
              <div className="flex flex-row gap-3">
                <ArrowUpFromDot />
                {item.user}
              </div>
              <div className="flex mt-5">
                <div className="flex flex-col mt-2 text-left">
                  <p className="text-sm text-white">{item.text}</p>
                  {/* <p className="text-xs text-white"> {item.company}</p> */}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
