import React from "react";
// index.js or App.js
import "./index.css"; // Adjust the path if necessary

import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import FeatureSection from "./components/FeatureSection";
import Workflow from "./components/Workflow";
import Pricing from "./components/Pricing";
import Testimonials from "./components/Testimonials";
import Footer from "./components/Footer";
import CarouselParent from "./components/CarouselParent";

const App = () => {
  return (
    <>
      <Navbar />

      <HeroSection />
      <div className="max-w-7xl mx-auto pt-20 px-6 over">
        <FeatureSection />
        <Workflow />
        {/* <Pricing /> */}
        <Testimonials />
      </div>

      <CarouselParent />
      <Footer />
    </>
  );
};

export default App;
