import React from "react";
import { resourcesLinks } from "../constants";
import { FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div id="Contact" className="flex flex-col mx-20">
      <div className="mt-10 border-t border-neutral-700 py-4">
        <div className="flex">
          <div className="flex flex-col">
            <p className="font-semibold mb-2 text-xl">Resources</p>
            {resourcesLinks.map((item, index) => (
              <a key={index} className="mb-1" href={item.href}>
                {item.text}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row my-10 justify-between">
        <div className="flex flex-col mb-6 lg:mb-0">
          <h2 className="font-semibold text-xl">Copyright By</h2>
          <p>©2023 Bizconnect Solutions, LLC. All rights reserved</p>
        </div>
        <div className="flex flex-col mb-6 lg:mb-0">
          <h2 className="font-semibold text-xl">Contact</h2>
          <p>info@bizconnect.solutions</p>
        </div>
        <div className="flex flex-col mb-6 lg:mb-0">
          <h2 className="font-semibold text-xl">Address</h2>
          <p>Romulus, Michigan, United States</p>
        </div>
        <div className="flex flex-col">
          <h2 className="font-semibold text-xl">Socials</h2>
          <a
            href="https://www.linkedin.com/company/bizconnect-solutions/"
            className="mb-1 flex items-center"
          >
            <FaLinkedin className="mr-2" /> LinkedIn
          </a>
          <a
            href="https://www.facebook.com/BizConnect.Solutions?mibextid=ZbWKwL"
            className="mb-1 flex items-center"
          >
            <FaFacebook className="mr-2" /> Facebook
          </a>
          <a
            href="https://www.instagram.com/bizconnect.solutions?igsh=Y3p2MWEzdXh3dWU3"
            className="mb-1 flex items-center"
          >
            <FaInstagram className="mr-2" /> Instagram
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
