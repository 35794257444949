import React from "react";
import { features } from "../constants";

const FeatureSection = () => {
  return (
    <div
      id="Feature"
      className="relative mt-20 pb-10 border-b border-neutral-800 min-h-[800px]"
    >
      <div className="text-center">
        <span className="bg-[#0434ff] text-white rounded-full text-xl tracking-wide font-medium px-3 py-1 uppercase">
          Features
        </span>
        <div className="text-3xl sm:text-5xl lg:text-7xl mt-10 lg:mt-20 tracking-wide">
          Easily grow
          <span className="text-[#0434ff]"> your business</span>
        </div>
      </div>
      <div className="flex flex-wrap mt-10 lg:mt-20">
        {features.map((feature, index) => (
          <div key={index} className="w-full sm:w-1/2 lg:w-1/2 p-8 gap-y-3">
            <div className="flex flex-col p-8 items-center text-center bg-white shadow-lg border-b-4 border-[#0434ff] lg:h-72">
              <div className="flex mx-6 h-10 w-10 p-2 bg-[#040404] text-[#0434ff] justify-center items-center rounded-full">
                {feature.icon}
              </div>
              <div>
                <h5 className="mt-1 mb-6 text-xl text-[#040404]">
                  {feature.text}
                </h5>
                <p className="text-md p-2 mb-20 text-neutral-500">
                  {feature.description}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeatureSection;
