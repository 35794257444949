import React from "react";
import video1 from "../assets/video3.mp4";
import video2 from "../assets/video1.mp4";
import globe from "../assets/globe.png";

import { useEffect } from "react";
import { motion } from "framer-motion";

const HeroSection = () => {
  useEffect(() => {
    const animateText = document.querySelectorAll(".animate-text");
    animateText.forEach((element, index) => {
      element.style.animationDelay = `${index * 0.2}s`;
    });
  }, []);

  return (
    <div className="flex flex-col items-center bg-[#040404] pt-20 p-10">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        {/* <img src={globe} alt="" /> */}
      </motion.div>
      <motion.h1
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
        className="text-[#0434ff] font-bold text-3xl md:text-4xl lg:text-6xl text-center tracking-wide animate-text"
      >
        BizConnect Solutions
        {/* <span className="text-[#0434ff] bg-clip-text"> for businesses</span> */}
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        className="mt-10 text-lg text-center text-white max-w-4xl tracking-wider animate-text"
      >
        Helping M&A professionals close more deals by delivering exclusive,
        off-market deal opportunities.
      </motion.p>
      <motion.h2
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7, duration: 1 }}
        className="text-2xl text-center tracking-wider mt-5 text-white animate-text"
      >
        Trusted by M&A Advisors, Business Brokers, Investment Banks & Private
        Equity Professionals
      </motion.h2>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 1, duration: 0.8, ease: "easeOut" }}
        className="flex justify-center my-10"
      >
        <a
          href="mailto:info@bizconnect.solutions"
          className="bg-gradient-to-r from-[#0434ff] to-[#5d71ce] text-white py-2 px-3 rounded-md hover:scale-105 transform transition duration-300"
        >
          Connect with us
        </a>
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2, duration: 1 }}
        className="flex mt-10 justify-center"
      >
        <motion.video
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.5, duration: 1 }}
          autoPlay
          loop
          muted
          className="border w-1/2 border-[#040404] shadow-[#040404] mx-3 my-4 rounded-lg"
        >
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag
        </motion.video>
        <motion.video
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1.7, duration: 1 }}
          className="border w-1/2 border-[#040404] shadow-[#040404] mx-3 my-4 rounded-lg"
        >
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </motion.video>
      </motion.div>
    </div>
  );
};

export default HeroSection;
