import React from "react";
import logo from "../assets/logo-new.png";
import { navItems } from "../constants";
import { Menu, X } from "lucide-react";
import { useState } from "react";

const Navbar = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const toggleNavbar = () => {
    setMobileDrawerOpen(!mobileDrawerOpen);
  };

  return (
    <nav className="sticky top-0 z-50 py-3 backdrop-blur-lg border-b border-neutral-700/80">
      <div className="container px-4 mx-auto relative text-sm">
        <div className="flex justify-between items-center">
          <div className="flex items-center flex-shrink-0 m-0 p-0 bg-white rounded-md">
            <img
              className="h-10 object-contain p-1" // Adjusted height and added padding
              src={logo}
              alt="logo"
            />
          </div>
          <ul className="hidden lg:flex ml-14 space-x-12">
            {navItems.map((item, index) => (
              <li key={index}>
                <a href={item.href}>{item.label}</a>
              </li>
            ))}
          </ul>
          <div className="hidden lg:flex justify-center space-x-12 items-center">
            <a
              href="mailto:info@bizconnect.solutions"
              className="py-2 px-3 border rounded-md bg-[#0434ff] text-white"
            >
              Connect with us
            </a>
            {/* <a
            href="#"
            className="bg-gradient-to-r from-orange-500 to-orange-800 py-2 
            px-3 rounded-md"
          >
            Create an account
          </a> */}
          </div>
          <div className="lg:hidden md:flex flex-col justify-end">
            <button onClick={toggleNavbar}>
              {mobileDrawerOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
        {mobileDrawerOpen && (
          <div
            className="fixed right-0 z-20 bg-neutral-900 w-full
          p-12 flex flex-col justify-center items-center text-center lg:hidden"
          >
            <ul>
              {navItems.map((item, index) => (
                <li key={index} className="py-4">
                  <a href={item.href}>{item.label}</a>
                </li>
              ))}
            </ul>
            <div className="flex space-x-6">
              {/* <a href="#" className="py-2 px-3 border rounded-md">
              Sign In
            </a> */}
              <a
                href="mailto:info@bizconnect.solutions"
                className="bg-[#0434ff] text-black rounded-md px-3 py-2"
              >
                Connect with us
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
export default Navbar;
